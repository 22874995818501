/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMetricData = /* GraphQL */ `
  query GetMetricData($valveName: String) {
    getMetricData(valveName: $valveName)
  }
`;
export const getValve = /* GraphQL */ `
  query GetValve($id: ID!) {
    getValve(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const listValves = /* GraphQL */ `
  query ListValves(
    $filter: ModelValveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
