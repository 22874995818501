/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateValve = /* GraphQL */ `
  subscription OnCreateValve {
    onCreateValve {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateValve = /* GraphQL */ `
  subscription OnUpdateValve {
    onUpdateValve {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteValve = /* GraphQL */ `
  subscription OnDeleteValve {
    onDeleteValve {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
