/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6uyjuuxnhvdpblrl7wyidq7snq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dhss2u3nlze5nj6o6st4xvrg4q",
    "aws_cognito_identity_pool_id": "us-east-1:b72beb85-568e-42c8-b012-152f1dbd5ccd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uUocWgUqH",
    "aws_user_pools_web_client_id": "vh4cl97c0l4qg53r84m6tarnb",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
