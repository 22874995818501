import React from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { listValves, getMetricData } from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge, Card, Col, Row } from 'react-bootstrap'
import { LineChart, Label, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart } from 'recharts';
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

var subscription = null;

const tooltipFormatter = (value, name, props) => {
    let newValue =  value === 1 ? 'closed' : 'open';
    return [newValue];
}

class ValveList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            valves: [],
        };
    }

    componentDidMount() {
        this.fetchValves();
        this.getMetricData('valve1');
        this.getMetricData('valve2');
        this.getMetricData('valve3');
        this.getMetricData('valve4');
        this.createSubscription();
    }

    componentWillUnmount() {
        if (subscription != null) {
            subscription.unsubscribe();
        }
    }

    async fetchValves() {
        try {
          const valveData = await API.graphql(graphqlOperation(listValves));
          const valves = valveData.data.listValves.items;
          const sortedValves = valves.sort((a, b) => (a.name > b.name) ? 1 : -1).slice(0,2);
          this.setState( {valves: sortedValves});
        } catch (err) { 
            console.log('error fetching valves');
        }
    }

    async getMetricData(valveName) {
        try {
            const data = await API.graphql(graphqlOperation(getMetricData, {valveName: valveName}));
            let arr = JSON.parse(data.data.getMetricData);
            arr.forEach(function (element) {
                element['time'] = new Date(element['recordedAt']).toLocaleTimeString();
            });
            const metrics = arr;
            this.setState({[`${valveName}Metrics`]: metrics});
            let last_element = arr[arr.length - 1];
            this.setState({[`${valveName}LastReportedAt`]: last_element['time']});
        } catch (err) {
              console.log(`error getting metric data for ${valveName}`);
        }
    }

    createSubscription() {
        subscription = API.graphql(
        graphqlOperation(subscriptions.onUpdateValve)
        ).subscribe({
        next: data => {
            this.processUpdate(data.value.data.onUpdateValve);
        }
        });
    }

    processUpdate(valve) {
        // updating valve states
        let newValves = [...this.state.valves];
        let index = newValves.findIndex(o => o.name === valve.name);
        if (index >= 0) {
            newValves[index] = valve;
            this.setState( {valves: newValves} );
        }

        // updating valve metrics
        let newElement = { 
            recordedAt: valve.updatedAt,
            status: valve.status === 'open' ? 0 : 1,
            time: new Date(valve.updatedAt).toLocaleTimeString()
         };
        let newMetrics = [...this.state[`${valve.name}Metrics`], newElement];
        this.setState({[`${valve.name}Metrics`]: newMetrics});
        this.setState({[`${valve.name}LastReportedAt`]: newElement['time']});
    }

    renderContent() {
        return (
            <div>
                <Row xs={1} md={2} className="g-4">
                    {this.state.valves.map((valve, index) => (
                        <Col>
                            <Card className="text-center">
                                <Card.Header><h4>{valve.name}</h4></Card.Header>
                                <Card.Body>
                                    <h1><Badge bg={valve.status === 'closed' ? 'danger' : 'success'}>{valve.status}</Badge></h1>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart width={500} height={200} data={this.state[`${valve.name}Metrics`]}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="time"/>
                                            <YAxis tickCount={2} />
                                            <Tooltip formatter={tooltipFormatter} />
                                            <Line type="monotone" dataKey="status" stroke="#8884d8" fill="#8884d8" type="line" strokeWidth={0} dot={{ strokeWidth: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Card.Body>
                                <Card.Footer className="text-muted">Last reported at <b>{this.state[`${valve.name}LastReportedAt`]}</b></Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }

    render() {
        return (
            this.renderContent()
        );
    }
}

export default ValveList;