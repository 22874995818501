/* src/App.js */
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Row } from 'react-bootstrap'
import ValveList from './components/valveList';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const App = () => {
  return (
      

      <div>
        <AmplifyAuthenticator>
          <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand href="#home">
                <img
                    alt=""
                    src="/p66-logo.png"
                    width="45"
                    height="45"
                    className="d-inline-block align-center"
                />{' '}
                  Digital Village
                {' | '}
                <img
                  alt=""
                  src="/aws_logo.svg"
                  width="45"
                  height="45"
                  className="d-inline-block align-top"
                />{' '}
              on Spot
              <img
                    alt=""
                    src="/spot.png"
                    width="90"
                    height="45"
                    className="d-inline-block align-center"
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <AmplifySignOut />
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <Container>
            <Row><ValveList/></Row>
          </Container>
        </AmplifyAuthenticator>
      </div>
  )
}

export default App